@import "../../styles/variables";

nav.the-menu {
    height: $header-height;
    padding: 20px 0;
    position: fixed;
    width: 100%;
    z-index: 99;

    &.the-menu-white {
        background-color: #FFF;
        box-shadow: 0px 5px 16px rgba(185, 185, 185, 0.25);
        animation: TheMenuWhite 1s;

        @keyframes TheMenuWhite {
            from { background-color: transparent;}
            to { background-color: #FFF }
        }

        .the-menu-link-facaparte {
            color: $cl-pink;
            border: 3px solid $cl-pink;
        }

        a {
            color: $cl-black;
        }

        a:not(.the-menu-link-facaparte) {
            animation: TheMenuWhiteFonts 1s;
        
            @keyframes TheMenuWhiteFonts {
                from { color: #FFF }
                to { color: $cl-black }
            }
        }
    }

    &.the-menu-transparent {
        background-color: transparent;
        animation: TheMenuTransparent 1s;
        
        @keyframes TheMenuTransparent {
            from { background-color: #FFF;}
            to { background-color: transparent }
        }

        .the-menu-link-facaparte {
            color: #FFF;
            border: 3px solid #FFF;
        }

        a {
            color: #FFF;
        }

        a:not(.the-menu-link-facaparte) {
            animation: TheMenuTransparentFonts 1s;
        
            @keyframes TheMenuTransparentFonts {
                from { color: $cl-black }
                to { color: #FFF }
            }
        }
    }

    .the-menu-container {
        display: flex;
        flex-direction: row;
    }

    ul {
        padding-left: 0;
        display: flex;
        margin: 0 0 0 auto;

        li {
            list-style: none;
            padding: 10px 0;

            a {
                display: block;

                &:hover:not(.the-menu-link-facaparte) {
                    border-bottom: 1px solid #FFF;
                }
            }
        }
    }

    .ellipse {
        width: 7px;
        height: 7px;
        background-color: $cl-orange1;
        border-radius: 50%;
        margin-right: 24px;
        margin-left: 24px;
        position: relative;
        top: 20px;
    }

    .the-menu-link-facaparte {
        position: relative;
        top: -5px;
    }

    .the-menu-hamburger {
        width: 25px;
        height: 25px;
        background-image: url('../../assets/images/menu.png');
        background-color: transparent;
        border: none;
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center center;
    }
}