@import "../../styles/variables.scss";

.club-advantages-landing-1 {
    // background: linear-gradient(157.25deg, rgba(255, 255, 255, 0.9) -16.48%, #FFFFFF 29.96%);
    background-image: url("../../assets/bg/bg-5.png");
    background-repeat: no-repeat;
    background-size: cover;
    padding: 50px 0;
    height: 100%;

    h2 {
        font-family: Montserrat;
        line-height: 140%;

        span {
            font-weight: bold;
        }
    }

    .slick-slider {
        width: 100%;
        margin-bottom: 42px;

        .slick-dots {
            position: unset !important;
    
            li {
                border-radius: 50%;
                width: 12px;
                height: 12px;
                background-color: $cl-grey;
                
                button {
                    display: none;
                }
    
                &.slick-active { 
                    background-color: $cl-orange1;
                }
            }
        }
    }
}

.club-advantages-landing-1-box {
    vertical-align: middle;
}

button.clube-vantagens-cta {
    @media(max-width: 767px) {
        font-size: 16px !important;
        padding-left: 30px !important;
        padding-right: 30px !important;
    }

    @media(max-width: 280px) {
        font-size: 16px !important;
        padding-left: 15px !important;
        padding-right: 15px !important;
    }
}

.club-advantages-landing-1-arrow-down {
    text-align: center;
    position: absolute;
    bottom: 1%;
    left: 50%;
    z-index: 0;
}