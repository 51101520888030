@import "../../styles/variables.scss";

article.main-section.section-5,
body.bg-lp-3 {
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url('../../assets/bg/bg-2.png');
    background-position: bottom center;
    background-attachment: fixed;
}

.assinatura-l1 {
    height: 100%;

    @media(max-height: 900px) {
        height: auto;
    }

    @media(max-width: 991px) {
        height: auto;
    }

    h2 {
        font-size: 32px;
    }

    h3 {
        line-height: 38px;
        font-size: 24px;
    }
}

.assinatura-l1-container {
    display: flex;
    // margin-bottom: 50px;

    @media(max-width: 991px) {
        display: block;
        padding: 0 5px;
    }
}

.assinatura-l1-text {
    position: relative;

    @media(max-width: 991px) {
        width: 100%;
        padding: 20px;
    }
}

.assinatura-l1-box {
    vertical-align: top;
    padding: 150px 0 100px;
}

.assinatura-l1-box-wrap {
    min-height: 800px;
}

.assinatura-l1-magazines {
    background-repeat: no-repeat;
    background-image: url('../../assets/images/landing-page-1/assinaturas-1.png');
    background-position: center center;
    background-size: contain;
    width: 100%;
    position: absolute;
    margin-top: 50px;
    min-height: 500px;

    @media(max-width: 1199px) {
        width: 90%;
    }

    @media(max-width: 991px) {
        position: static;
        margin: 0;
        width: 100%;
        min-height: 300px;
    }
}

.assinatura-l1-form {
    width: 100%;
    background-color: $cl-white;
    border-radius: 24px;
    color: $cl-black;
    padding: 45px;
    margin-left: auto;
    max-width: 540px;

    @media(max-width: 1199px) {
        max-width: 450px;
    }

    @media(max-width: 991px) {
        width: 100%;
        max-width: 100%;
        padding: 30px;
    }

    label {
        font-size: 14px;
        font-weight: 500;
        display: block;
        margin-bottom: 5px;
        padding-top: 5px;
    }

    a, .open-modal-text {
        color: $cl-pink;
        padding-bottom: 2px;
        border-bottom: 1px solid $cl-pink;
        cursor: pointer;

        &:hover {
            color: $cl-pink;
        }
    }

    .checkbox-label {
        color: #8A94A6;
        position: relative;
        padding-left: 30px;

        input {
            position: absolute;
            left: 0;
            top: 5px;
            width: 20px;
            height: 20px;
            border: 1px solid #F3F1F1;
        }

        input[type=checkbox]:checked {
            &:before {
                content: ' ';
                background-color: #FFF;
                background-image: url("../../assets/images/checkbox.png");
                width: 20px;
                height: 20px;
                display: block;
            }
        }
    }
}

.assinatura-l1-form {
    @media(min-width: 992px) {
        margin-bottom: 50px;
    }

    @media(max-width: 375px) {
        .the-button {
            padding: 12px 25px !important;
        }
    }
}

.assinatura-landing-1-footer {
    @media(min-width: 993px) {
        position: absolute;
        bottom: 0;
        width: 100%;
    }
}