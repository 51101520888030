@import "../../styles/variables.scss";

html,
body,
div#app,
div[data-testid=app],
.main-app {
    height: 100%;
}

body {
    font-family: 'Lato', sans-serif;
    overflow-x: hidden;
}

a {
    &:hover {
        text-decoration: none;
        cursor: pointer;
    }
}

img {
    max-width: 100%;
}

a.link-default {
    color: $cl-pink;
    border-bottom: 1px solid $cl-pink;

    &:hover {
        color: $cl-pink;
    }
}

.container {
    @media (min-width: 1200px) {
        max-width: 1220px;
    }
}

input[type=text],
input[type=password] {
    padding: 12px 15px;
    font-size: 16px;
    border: 1px solid #F3F1F1;
    border-radius: 8px;
    width: 100%;

    &::placeholder {
        color: $cl-grey;
    }
}

.tabs-list {
    ul {
        padding-left: 0;
        margin: 0;
        max-height: 400px;
        overflow-y: scroll;
        box-shadow: #e4e4e4 0 -20px 30px -2em inset;
    }
    
    li {
        list-style: none;
        padding: 25px 0;
        border-bottom: 1px solid #CCC;

        &:last-child {
            border-bottom: 0;
        }
    }

    h5 {
        font-weight: 600;
        font-size: 18px;
        font-family: 'Montserrat', sans-serif;
        margin-bottom: 10px;
        display: flex;
        padding-right: 10px;

        button {
            margin-left: auto;
            background-color: transparent;
            background-image: url("../../assets/images/arrow-pink-right.png");
            border: none;
            background-repeat: no-repeat;
            background-position: center center;
            width: 12px;
            display: inline-block;
            height: 12px;
        }
    }

    p {
        font-size: 16px;
        color: #999;
    }
}

li.tab-active {
    button {
        background-image: url("../../assets/images/arrow-pink-up.png");
    }
}

html {
    scroll-behavior: smooth;
}



.responsive-iframe {
    position: relative;
    overflow: hidden;
    width: 100%;
    padding-top: 56.25%;

    iframe {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        width: 100%;
        height: 100%;
    }
}

.btn-disabled {
    color: $cl-silver !important;
    background-color: $cl-grey !important;
}

.form-alert {
    text-align: center;
    font-size: 14px;
    color: $cl-pink;
}

.input-radio {
    padding-left: 25px;
    padding-right: 10px;
    position: relative;

    input {
        width: 0;
        position: absolute;
        left: 0;

        &::after {
            background-image: url('../../assets/images/input-radio.png');
            content: ' ';
            display: inline-block;
            width: 20px;
            height: 20px;
        }

        &:checked {
            &::after {
                background-image: url('../../assets/images/input-radio-actived.png');
                content: ' ';
                display: inline-block;
                width: 20px;
                height: 20px;
            }   
        }
    }

    span {
        font-size: 14px;
        position: relative;
        top: -2px;
    }
}

// article.main-section.home-section-2 {
//     background-color: #F3F1F1;
// }