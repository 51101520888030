@import "../../styles/variables.scss";

.lancamentos-l1 {
    background-repeat: no-repeat;
    background-size: cover;
    padding: 100px 0 74px;
    background-image: url('../../assets/bg/bg-4.png');
    background-position: center center;
    min-height: 100%;

    .slick-arrow {
        &.slick-next {
            width: 11px;
            height: 20px;
        }
        &.slick-prev {
            width: 11px;
            height: 20px;
        }
    }
}

.lancamentos-l1-slide-container {
    display: flex;
    justify-content: flex-end;

    @media(max-width: 991px) {
        display: block;
    }

    .slick-arrow {
        z-index: 99;
    }
}

.lancamentos-l1-text {
    @media(max-width: 991px) {
        width: 100%;
        text-align: center;
    }

    h4 {
        font-size: 40px;

        @media(max-width: 1400px) {
            font-size: 30px;
        }

        @media(max-width: 767px) {
            font-size: 20px;
        }
    }
}

.lancamentos-l1-text-content {
    margin: 70px 70px 70px 25px;
    max-width: 315px;

    @media(max-width: 1400px) {
        max-width: 280px;
    }

    @media(max-width: 991px) {
        width: 100%;
        text-align: center;
        margin: 30px auto;
    }

    @media(max-width: 767px) {
        max-width: 180px;
    }
}

.lancamentos-l1-slide {
    width: 1000px;
    min-height: 337px;
    margin-bottom: 50px;
    padding: 0 3% 0 3%;
    position: relative;
    overflow: hidden;

    &:after {
        background: rgb(255,255,255);
        background: linear-gradient(275deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%);
        content: ' ';
        display: inline-block;
    }

    @media(max-width: 1400px) {
        width: 800px;
    }

    @media(max-width: 1200px) {
        width: 600px;
    }

    @media(max-width: 991px) {
        width: 100%;
        min-height: auto;
        padding: 0;
    }
}

.slide-item-magazine {
    padding: 0 15px;

    img {
        max-width: 100%;
    }
}

.lancamentos-l1-box {
    vertical-align: middle;
}

.lancamentos-l1-assine {
    max-width: 860px;
    margin: 0 auto;
    background: #FFFFFF;
    box-shadow: 0px 34px 27px -26px rgba(46, 32, 32, 0.15);
    border-radius: 24px;
    padding: 50px;

    @media(max-width: 1199px) {
        max-width: 100%;
        text-align: center;
    }
}

.lancamentos-l1-assine-cartoes {
    max-width: 200px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    @media(max-width: 1199px) {
        max-width: 100%;
    }

    img {
        flex: 0 50%;
        max-width: 90px;
        padding: 15px 7px;

        @media(max-width: 767px) {
            max-width: 25%;
        }
    }
}

.lancamentos-l1-magazine-gallery {
    display: flex;
    justify-content: flex-end;
    margin: 0 auto 50px;

    @media(max-width: 1449px) {
        display: block;
    }
}

.magazine-gallery-slide {
    margin: 0 10px;
}

.magazine-gallery-slide-cover {
    width: 320px;
    height: 420px;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center center;

    @media(max-width: 1449px) {
        width: 280px;
        height: 367px;
    }
}

.lancamento-slide-info {
    box-shadow: 0px 5px 16px rgba(185, 185, 185, 0.25);
    border-radius: 8px;
    width: 250px;
    padding: 25px;
    margin: -40px auto 20px;

    @media(max-width: 767px) {
        width: calc(100% - 40px)
    }
}

.lancamentos-l1-arrow-down {
    text-align: center;
    position: absolute;
    bottom: 1%;
    left: 50%;
    z-index: 0;
}