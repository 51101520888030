@import "../../styles/variables.scss";

footer.the-footer {
    background-color: $cl-white;
    padding-top: 25px;
    padding-bottom: 25px;
    font-size: 13px;
    // position: absolute;
    // bottom: 0;
    // width: 100%;

    img {
        max-width: 150px;
        width: 100%;

        @media(max-width: 767px) {
            max-width: 125px;
        }
    }
}

a.footer-links {
    display: block;
    color: $cl-black;
    font-weight: 500;
}

ul.the-footer-links {
    padding-left: 0;
    margin: 0;
    text-align: right;

    @media(max-width: 767px) {
        text-align: left;
    }

    li {
        list-style: none;
        display: inline-block;
        margin: 12px 25px;

        @media(max-width: 767px) {
            display: block;
            margin: 0 0 21px;
        }

        &:last-child {
            margin-bottom: 0;
            margin-right: 0;
        }
    }
}

.the-footer-app-store {
    @media(max-width: 767px) {
        margin-bottom: 25px;
        display: block;
    }
}

.the-footer-termos-condicoes,
.the-footer-politica-privacidade {
    max-height: 400px;
    overflow-y: scroll;

    p {
        font-size: 16px;
        color: #999;
    }
}