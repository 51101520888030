article.main-section {
    height: 100%;

    @media(max-width: 767px) {
        height: auto;
    }
}

article.section-1 {}

article.section-2 {}

article.section-3 {}

article.section-4 {}

article.section-5 {
    min-height: 1300px;
}