@import "../../styles/variables.scss";

article.main-section.section-5 {
    background-image: none !important;
}

.assinatura-l1-box-wrap {
    min-height: auto !important;
}

.assinatura-l1-box {
    padding-top: 50px;

    @media(max-width: 767px) {
        padding-top: 75px !important;
    }
}

.assinatura-l3-text{
    position: relative;
    min-height: 90px;
    width: 100%;
}

.assinatura-l3-magazines{
    background-repeat: no-repeat;
    background-image: url('../../assets/images/landing-page-1/assinaturas-1.png');
    background-position: center center;
    background-size: contain;
    width: 100%;
    position: absolute;
    margin-top: 50px;
    min-height: 500px;

    @media(max-width: 1199px) {
        width: 90%;
    }

    @media(max-width: 991px) {
        position: static;
        width: 100%;
        min-height: 300px;
    }
    padding-top: 100px !important;

    @media(max-width: 767px) {
        padding-top: 75px !important;
    }
}

nav.the-menu {
    position: static;
}

.assinatura-l1-box {
    padding-top: 25px;
}

input[name=customerEmail] {
    border-radius: 4px;
    -moz-border-radius: 4px;
    -webkit-border-radius: 4px;
    box-shadow: 1px 1px 2px #1ac417;
    -moz-box-shadow: 1px 1px 2px #1ac417;
    -webkit-box-shadow: 1px 1px 2px #1ac417;
    border: 2px solid #1ac417 !important;
}

.the-button{
    background-color: #1ac417 !important;
}

.the-button[disabled]{
    background-color: #1ac417 !important;
    color: #532323 !important;
}