@import "../../styles/variables.scss";

@media (min-width: 991px) {
    .banner-l1,
    .banner-content-desktop {
        height: 100vh;
    }
}

.banner-l1 {
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url('../../assets/bg/bg-1.png');
    background-position: bottom center;
    padding: 100px 0 0 0;
    
    @media (min-width: 992px) {
        .banner-content-desktop {
            padding-left: 5%;

            #banner-1-item-1-img {
                position: absolute;
                bottom: 0;
                right: 0;
                width: 32%;
            }
        }

        .banner-content-mobile {
            display: none;
        }
    } 

    @media (max-width: 991px) {
        .banner-content-mobile {
            display: flex;
            flex-direction: column;
            justify-content: center;
            padding: 0 15px;
        }

        .banner-content-desktop {
            display: none;
        }

        .banner-l1-text {
            display: flex;
            flex-direction: column;
            justify-content: center;
            max-width: 100% !important;
        }

        .banner-l1-cta {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-self: center;
        }
    }

    @media(max-width: 767px) {
        padding-top: $mobile-nav-height;
    }

    a {
        color: #FFF;
        padding-bottom: 2px;
        border-bottom: 1px solid #FFF;

        &:hover {
            color: #FFF;
        }
    }

    h2 {
        font-size: 60px;
        margin-bottom: 30px;

        @media(max-width: 767px) {
            font-size: 30px;
            margin-bottom: 15px;
        }
    }

    h3 {
        line-height: 45px;
        font-size: 28px;

        @media(max-width: 767px) {
            font-size: 18px;
            line-height: 29px;
            margin-bottom: 40px;
        }
    }

    h4 {
        margin-bottom: 35px;
    }

    button.the-button {
        @media(max-width: 767px) {
            font-size: 20px;
        }
    }
}

.banner-l1-img-mobile {
    position: relative;
    right: -15px;
}

.banner-l1-text {
    max-width: 600px;
    padding-top: 50px;

    @media(max-width: 990px) {
        padding-top: 15px;
    }

    @media (min-width: 991px) {
        .banner-l1-img-mobile {
            display: none;
        }
    }

    @media (max-width: 991px) {
        h2,
        h3,
        h4 {
            padding: 0 15px;
        }
    }
}

.banner-l1-img-desktop {
    display: flex;
    flex-direction: column;
    justify-content: end;
    align-items: flex-end;
    height: 100%;

    img {
        height: 80%;
    }

    @media (max-width: 991px) {
        img {
            display: none;
        }
    }
}

.banner-l1-cta {
    max-width: 300px;
    text-align: center;

    img {
        width: 10px;
        margin-left: 10px;
    }

    a {
        border-bottom: 0 !important;
    }

    .more-details-span {
        color: #FFF;
        padding-bottom: 2px;
        border-bottom: 1px solid #FFF;
        cursor: pointer;
        
        @media(max-width: 320px) {
            font-size: 20px !important;
        }

        @media(max-width: 280px) {
            font-size: 15px !important;
        }
    }

    @media(max-width: 767px) {
        max-width: 100%;
    }
}

.banner-l1-arrow-down {
    text-align: center;
    position: absolute;
    bottom: 50px;
    left: 50%;
    z-index: 0;

    a {
        border-bottom: 0;
    }

    @media (max-width: 991px) {
        padding-bottom: 50px;
    }

    @media(max-width: 767px) {
        position: static;
        margin-top: 20px;
    }
}