@import "../../styles/variables.scss";

#assinatura-form {
    .assinatura-l1-pagamento-title {
        display: flex;
    
        @media(max-width: 991px) {
            display: block;
        }
    }
    
    .assinatura-l1-form-title {
        font-weight: 600;
        font-size: 20px;
        font-family: 'Montserrat', sans-serif;
    }

    #credit-card-info {
        display: none;
    }
}

.assiantura-recaptcha {
    @media(max-width: 991px) {
        // transform: scale(0.9);
        position: absolute;
    }
}

.recaptcha-space-fake {
    @media(max-width: 991px) {
        display: block;
        width: 100%;
        height: 80px;
    }
}

.input-error {
    border-color: $cl-pink !important;

    &:focus {
        outline-color: $cl-pink;
    }
}

.error-message {
    font-size: 12px;
    line-height: 20px;
    color: red;
    margin-top: 5px;
    display: none;
}