@import "../../styles/variables";

button.the-button, a.the-button {
    font-family: 'Lato', sans-serif;
    font-weight: 700;
    border-radius: 40px;
    border: none;

    &.btn-lg {
        font-size: 24px;
        padding: 16px 38px;
    }

    &.btn-md {
        font-size: 16px;
        padding: 12px 45px;
    }

    &.bg-pink {
        background-color: $cl-pink;
    }
}