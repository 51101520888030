@import "../../styles/variables.scss";

.icon-ellipse-container {
    display: flex;

    .icon-ellipse {
        background: $gr-orange;
        width: 60px;
        height: 60px;
        border-radius: 50px;
    }
}