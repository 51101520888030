@import "../../styles/variables.scss";

.como-funciona-l1 {
    background-repeat: no-repeat;
    background-size: cover;
    padding: 100px 0;
    background-image: url('../../assets/bg/bg-3.png');
    background-position: center center;
    height: 100%;

    @media(max-width: 991px) {
        height: auto;
    }

    @media(max-height: 900px) {
        height: auto;
    }

    a {
        color: $cl-pink;
        padding-bottom: 2px;
        border-bottom: 1px solid $cl-pink;

        &:hover {
            color: $cl-pink;
        }
    }
}

.como-funciona-l1-box {
    vertical-align: middle;
}

.como-funciona-container {
    background: #FFFFFF;
    box-shadow: 0px 34px 27px -26px rgba(46, 32, 32, 0.15);
    border-radius: 24px;
    padding-bottom: 100px;

    @media(max-width: 767px) {
        padding-bottom: 70px;
    }
}

.como-funciona-content {
    padding: 100px 70px 50px;

    @media(max-width: 767px) {
        padding-top: 70px;
    }
}

.como-funciona-steps {
    display: flex;
    padding: 0 70px;

    @media(max-width: 991px) {
        display: block;
        padding: 0;
    }
}

.como-funciona-step {
    text-align: center;
    width: 100%;
    padding: 0 25px;
    background-image: url("../../assets/images/como-funciona-line.png");
    background-repeat: no-repeat;
    background-size: 690px;
    background-position: 0 35px;

    @media(max-width: 1199px) {
        padding: 0;
    }

    h4 {
        font-family: 'Montserrat', sans-serif;
    }
    
    p {
        color: $cl-silver;

        @media(max-width: 1199px) {
            padding: 0 30px;
        }
    }

    img {
        margin: 0 12px;
    }

    &.step-1 {
        background-position-x: 200px;

        @media(max-width: 1199px) {
            background-position-x: 100%;
            background-size: 50% 3px;
        }
    }

    &.step-2 {
        background-position-x: 50%;

        @media(max-width: 1199px) {
            background-position-x: 0;
            background-size: 100% 3px;
        }
    }

    &.step-3 {
        background-position-x: -500px;

        @media(max-width: 1199px) {
            background-position-x: 0;
            background-size: 50% 3px;
        }
    }
}

.como-funciona-step-ball {
    width: 70px;
    height: 70px;
    display: block;
    border-radius: 50%;
    margin: 0 auto 40px;
    background-position: center center;
    background-size: contain;
    background-color: #FFF;

    &:after {
        content: ' ';
        width: 25px;
        height: 70px;
        display: inline-block;
        background-image: contain;
        background-repeat: no-repeat;
        background-position: center center;
    }

    &.ball-1 {
        background-image: url("../../assets/images/como-funciona-circle-1.png");

        &:after {
            background-image: url("../../assets/images/como-funciona-1.png");
        }
    }
    
    &.ball-2 {
        background-image: url("../../assets/images/como-funciona-circle-2.png");

        &:after {
            background-image: url("../../assets/images/como-funciona-2.png");
        }
    }
    
    &.ball-3 {
        background-image: url("../../assets/images/como-funciona-circle-3.png");

        &:after {
            background-image: url("../../assets/images/como-funciona-3.png");
        }
    }
}

.como-funciona-stores-links {
    a {
        border-bottom: none !important;

        @media(max-width: 991px) {
            margin: 0 auto;
        }
    }
}

.como-funciona-l1-arrow-down {
    text-align: center;
    position: absolute;
    left: 50%;
    z-index: 0;
    margin-top: 5%;
    
    a {
        border-bottom: 0;
    }
}

.como-funciona-book-icon {
    width: 80px;
}