// Solid colors
$cl-pink: #EB5757;
$cl-orange-white: #FFFADF;
$cl-orange1: #FC9601;
$cl-black: #21130E;
$cl-grey: #C4C4C4;
$cl-light-grey: #FAF9F9;
$cl-silver: #6B6B6B;
$cl-light-pink: #FFD9D9;

$mobile-nav-height: 80px;
$cl-white: #FFFF;

$home-nav-height: 82px;

// Gradients
$gr-orange: linear-gradient(42.7deg, rgba(235, 87, 87, 0.95) 9.58%, rgba(252, 150, 1, 0.95) 54.69%, rgba(252, 212, 1, 0.95) 115.2%);

// Header vars
$header-height: 80px;
$header-items-margin-desktop: 24px;
$header-items-margin-ipad-landscape: 12px;
